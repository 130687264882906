import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';

import { doConnect } from "../middleware/walletConnector";
import { getProtocolParams } from "../middleware/apiConnector";

import 'react-toastify/dist/ReactToastify.css';
import {
  BurgerBoi,
  Faq,
  Footer,
  Hero,
  Intro,
  ImageGrid,
  Layout,
  BurgerMenu,
  Navigation,
  RarityBrowser,
  Roadmap,
  Spidey,
} from "../components";


import "./index.scss";
import { useEffect } from "react";
import { features } from "../config/features";

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || "";

const IndexPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [walletConnected, setWalletConnected] = useState();
  const [protocolParams, setProtocolParams] = useState();
  const [selectedWallet, setSelectedWallet] = useState('');
  const [connectingWallet, setConnectingWallet] = useState();
  const [wallet, setWallet] = useState({});

  const notConnectedText = (selectedWallet) => `Uh-oh! We could not connect to your ${capitalize(selectedWallet)} wallet. Are you sure you're using Chrome and have it installed?`;
  const connectedText = (selectedWallet) => `${capitalize(selectedWallet)} connected! Coo-coo!`;

  const cookies = new Cookies();
  const cookieName = 'selectedWallet';

  useEffect(() => {
    if(features.mintButton){
      const selectedWalletFromCookie = cookies.get(cookieName);
      if (selectedWalletFromCookie) {
        handleConnect(selectedWalletFromCookie)
      }
    }
  }, []);

  useEffect(async () => {
    if (connectingWallet) {
      try {
        const params = await getProtocolParams();
        setProtocolParams(params);

        const { response, wallet } = await doConnect(params, connectingWallet);

        if (response.status === 'success') {
          setSelectedWallet(connectingWallet);
          toast(connectedText(connectingWallet));
          setWalletConnected(true);
          setConnectingWallet();
          setWallet(wallet);
          if (cookies.get(cookieName) !== connectingWallet) {
            cookies.set(cookieName, connectingWallet, { path: ' / ' });
          }
        } else {
          toast(notConnectedText(connectingWallet));
        }
      } catch {
        toast(notConnectedText(connectingWallet));
      }
    }
  }, [connectingWallet]);

  const handleConnect = async (selectedWallet) => {
    setConnectingWallet(selectedWallet)
  }

  const resetWalletConnection = () => {
    console.log('resetting wallet connection');
    setWalletConnected();
    setProtocolParams();
    setSelectedWallet('');
    setConnectingWallet();
    setWallet({});
    cookies.remove(cookieName);
  }

  const toggleMenu = () => {
    if (!menuOpen) {
      window.scrollTo(0, 0);
    }
    setMenuOpen(!menuOpen);
  };

  const goToAnchor = () => {
    toggleMenu();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cult of Pigeons</title>
        <link rel="canonical" href="https://cultofpigeons.io" />
        <meta property="og:site_name" content="Cult of Pigeons" />
        <meta property="og:title" content="Cult of Pigeons" />
        <meta
          property="og:description"
          content="Welcome to the Cult of Pigeons! The first ultra high quality art collection of 10.000 unique pigeons, ready to take over Cardano and after, the world!"
        />
        <meta
          property="og:image:secure_url"
          itemProp="image"
          content="https://d90xur3si4z2s.cloudfront.net/ogimage-256x256.png"
        />
        <meta
          property="og:image"
          itemProp="image"
          content="http://d90xur3si4z2s.cloudfront.net/ogimage-256x256.png"
        />
        <meta property="og:type" content="website" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#6260a5" />
        <meta name="msapplication-TileColor" content="#6260a5" />
        <meta name="theme-color" content="#ffffff" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-ESJ31HCZTL"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-ESJ31HCZTL');
          `}
        </script>
      </Helmet>

      <BurgerBoi
        handleConnect={handleConnect}
        open={menuOpen}
        toggle={toggleMenu}
        walletConnected={walletConnected}
        selectedWallet={selectedWallet}
      />
      <div className={`noscroll${menuOpen ? " menuOpen" : ""}`}>
        <Layout extraClass="purple">
          <Navigation />
        </Layout>
        {!walletConnected && <Hero />}
        <Layout extraClass="purple">
          <Intro
            handleConnect={handleConnect}
            walletConnected={walletConnected}
            protocolParams={protocolParams}
            selectedWallet={selectedWallet}
            wallet={wallet}
            resetWalletConnection={resetWalletConnection}
          />
        </Layout>
        <ImageGrid />
        <Layout extraClass="blue-to-purple" fullWidth>
          <RarityBrowser />
        </Layout>
        <Layout extraClass="purple">
          <Roadmap />
        </Layout>
        <Layout>
          <Spidey />
        </Layout>
        <Layout extraClass="purple-to-blue" fullWidth>
          <Faq />
        </Layout>
        <Layout extraClass="purple" fullWidth>
          <Footer />
        </Layout>
        <BurgerMenu goToAnchor={goToAnchor} />
        <ToastContainer theme="dark" autoClose={3000} />
      </div>
    </>
  );
};

export default IndexPage;
